const ProductGroupPricing = {
  add_to_price: 0,
  override_price: 1,

};

import i18n from '@/libs/i18n';

const ProductGroupPricingTranslations = [

  {
    id: 0,
    name: i18n.t('products.options.add_to_price')
  },
  {
    id: 1,
    name: i18n.t('products.options.override_price')
  },


];

function filterProductGroupPricing(id) {
  return ProductGroupPricingTranslations.find((item) => item.id === id);
}


export { ProductGroupPricing, ProductGroupPricingTranslations, filterProductGroupPricing };
